<template>
  <v-dialog
    v-if="isLoaded"
    v-model="modalState"
    width="900"
    persistent
  >
    <v-card :class="highlightForm && 'highlight-required-fields'">
      <v-form
        v-model="formValid"
        :class="isSigned ? 'form-disabled' : ''"
        :disabled="isSigned"
      >
        <div
          ref="dermTitle"
          class="sticky-header"
        >
          <PatientInformation
            :title="`#${wound.wound_number} Derm Detail Entry`"
            :patient="patient"
          >
            <!-- image attachments -->
            <btn
              label="Images"
              :icon="icons.mdiFileImage"
              class="px-5"
              @click="attachmentTypeClicked"
            >
              <v-badge
                v-if="imageAttachmentCount"
                color="primary"
                :content="imageAttachmentCount"
              >
                Images
              </v-badge>
            </btn>
          </PatientInformation>

          <!-- attachment modal -->
          <attachment-modal
            :attachment-modal.sync="attachmentModal"
            :attachment-type.sync="attachmentType"
            attachment-type-id="3"
            :encounter-attachments.sync="encounterAttachments"
            :patient-id="patient.id"
            :signed="isSigned"
          ></attachment-modal>
        </div>

        <NoteSection label="Location &amp; Etiology">
          <!-- location -->
          <text-field
            :value="wound.location.location_text"
            class="col-sm-7"
            label="Location"
            :append-icon="!!wound.location.location_text && icons.mdiClose"
            :disabled="wound.followup_treatment && !!wound.location.location_text"
            readonly
            required
            @click="locationModal = true"
            @click:append="clearLocation"
          ></text-field>

          <!-- etiology -->
          <select-box
            v-model="treatment.etiology"
            :items="dermEtiologies"
            label="Etiology"
            class="col-sm-5"
            item-value="title"
            :previous-value="hasDermTreatment && latestTreatment.etiology ? previousEtiology : null"
            :previous-value-dos="previousVisitDate"
            required
          ></select-box>

          <text-field
            v-if="treatment.etiology === 'Other'"
            v-model="treatment.etiology_other"
            label="Enter other etiology..."
            class="col-sm-12"
            counter="900"
            max-length="900"
            :previous-value="hasDermTreatment && latestTreatment.etiology_other ? latestTreatment.etiology_other : null"
            :previous-value-dos="previousVisitDate"
            :input-history-custom-styles="'padding-top: 18px;'"
            required
          ></text-field>

          <!-- location modal -->
          <Location
            v-model="wound.location"
            :modal-state.sync="locationModal"
          ></Location>
        </NoteSection>

        <div class="note-section mx-2">
          <!-- Alerts: Prevent validation (set color="error" and add required checkbox) -->
          <v-expand-transition>
            <div v-if="invalidFootProcedureLocation">
              <alert color="error">
                Procedure is inconsistent with location.
                <checkbox required></checkbox>
              </alert>
            </div>
          </v-expand-transition>

          <!-- Warnings: Allow validation -->
          <alert v-if="therapeuticAnticoagulant">
            Patient on anticoagulant!
          </alert>
        </div>

        <NoteSection label="Procedure Performed">
          <select-box
            v-model="treatment.procedure_id"
            :items="dermProcedures"
            label="Procedure Performed"
            class="col-sm-12"
            dense
            required
            :previous-value="hasDermTreatment && latestTreatment.procedure_id ? previousProcedureTitle : null"
            :previous-value-dos="previousVisitDate"
            input-history-custom-styles="padding-top: 18px;"
            :disabled="visitLocation === 'Telemedicine'"
          ></select-box>
        </NoteSection>

        <NoteSection label="Lesion Condition">
          <check-boxes
            v-model="derm_treatment.lesion_conditions"
            :items="lesionConditions"
            class="col-sm-12 mt--1"
            columns="3"
            column-sort
            hide-details
            :required="!isQuickEntry"
            object-items
            return-object
            :previous-value="previousLesionConditions"
            :previous-value-dos="previousVisitDate"
          ></check-boxes>

          <text-area
            v-if="derm_treatment.lesion_conditions.some(x => x.title === 'Other')"
            v-model="derm_treatment.lesion_conditions_other"
            label="Enter other lesion conditions..."
            class="col-sm-12"
            counter="1500"
            maxlength="1500"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.lesion_conditions_others
              ? latestTreatment.derm_treatment.lesion_conditions_other : null"
            :previous-value-dos="previousVisitDate"
            required
          ></text-area>
        </NoteSection>

        <NoteSection label="Duration">
          <select-box
            v-model="derm_treatment.duration"
            :items="durations"
            label="Duration"
            class="col-sm-12"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.duration
              ? latestTreatment.derm_treatment.duration : null"
            :previous-value-dos="previousVisitDate"
          ></select-box>
        </NoteSection>

        <NoteSection label="Topical Agent">
          <select-box
            v-model="derm_treatment.topical_agent"
            :items="topicalAgents"
            item-value="title"
            item-text="title"
            label="Topical Agent"
            class="col-sm-6"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.topical_agent
              ? latestTreatment.derm_treatment.topical_agent : null"
            :previous-value-dos="previousVisitDate"
            @click:clear="derm_treatment.topical_agent_strength = null"
          ></select-box>

          <text-field
            v-model="derm_treatment.topical_agent_strength"
            class="col-sm-6"
            :label="topicalAgentStrLabel"
            :disabled="!derm_treatment.topical_agent"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.topical_agent_strength
              ? latestTreatment.derm_treatment.topical_agent_strength : null"
            :previous-value-dos="previousVisitDate"
            :input-history-custom-styles="'padding-top: 18px;'"
          ></text-field>

          <select-box
            v-model="derm_treatment.secondary_topical_agent"
            :items="topicalAgents"
            item-value="title"
            item-text="title"
            label="Secondary Topical Agent"
            class="col-sm-6"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.secondary_topical_agent
              ? latestTreatment.derm_treatment.secondary_topical_agent : null"
            :previous-value-dos="previousVisitDate"
            @click:clear="derm_treatment.secondary_topical_agent_strength = null"
          ></select-box>

          <text-field
            v-model="derm_treatment.secondary_topical_agent_strength"
            class="col-sm-6"
            :label="secondaryTopicalAgentStrLabel"
            :disabled="!derm_treatment.secondary_topical_agent"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.secondary_topical_agent_strength
              ? latestTreatment.derm_treatment.secondary_topical_agent_strength : null"
            :previous-value-dos="previousVisitDate"
            :input-history-custom-styles="'padding-top: 18px;'"
          ></text-field>
        </NoteSection>

        <NoteSection label="Lesion Description">
          <text-area
            v-model="derm_treatment.lesion_description"
            label="Lesion Description"
            counter="4800"
            maxlength="4800"
            class="col-sm-12"
            :previous-value="hasDermTreatment && latestTreatment.derm_treatment.lesion_description
              ? latestTreatment.derm_treatment.lesion_description : null"
            :previous-value-dos="previousVisitDate"
            :required="!isQuickEntry"
          ></text-area>
        </NoteSection>

        <OperativeNote
          v-if="!isQuickEntry"
          v-model="treatment.operative_note"
          :treatments="wound.treatments"
          :treatment-id="treatment.id"
          :highlight-form="highlightForm"
          :procedure-id="treatment.procedure_id"
          :previous-op-note="previousProceduralNote"
          :previous-dos="previousVisitDate"
        ></OperativeNote>

        <NoteSection
          v-if="isQuickEntry"
          label="Quick Entry"
          heading
        ></NoteSection>

        <QuickEntry
          v-if="isQuickEntry"
          :current-treatment.sync="treatment.current_treatment"
          :qent_notes.sync="treatment.qent_notes"
          :operative-note.sync="treatment.operative_note"
          :treatments="wound.treatments"
        ></QuickEntry>

        <v-card-actions class="sticky-bottom">
          <!-- done -->
          <btn
            v-if="isSigned"
            label="Done"
            color="secondary"
            :icon="icons.mdiCheck"
            @click="done"
          ></btn>
          <!-- cancel -->
          <btn
            v-else
            label="Cancel"
            color="secondary"
            :icon="icons.mdiCancel"
            @click="cancel"
          ></btn>
          <!-- delete -->
          <btn
            v-if="isNewTreatment && !isSigned"
            label="Delete"
            color="error"
            :icon="icons.mdiTrashCan"
            rem::disabled="treatment.can_be_deleted !== true"
            @click="deleteTreatment"
          ></btn>

          <v-spacer></v-spacer>

          <!-- save draft -->
          <btn
            v-if="!isSigned"
            label="Save Draft"
            :icon="icons.mdiContentSaveEdit"
            color="success"
            @click="saveDraft"
          ></btn>

          <!-- validate/save -->
          <btn
            v-if="!isSigned"
            label="Validate / Save"
            :icon="icons.mdiCheckDecagram"
            @click="validateSave"
          ></btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AttachmentModal from '@/components/notes/encounter/AttachmentModal.vue'
import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'
import Location from '@/components/notes/wound-detail/treatments/sections/Location.vue'
import OperativeNote from '@/components/notes/wound-detail/treatments/sections/OperativeNote.vue'
import QuickEntry from '@/components/notes/wound-detail/treatments/sections/QuickEntry.vue'
import {
  mdiCancel,
  mdiCheck,
  mdiCheckDecagram,
  mdiClose,
  mdiContentSave,
  mdiContentSaveEdit,
  mdiFileImage,
  mdiTrashCan,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import InputHistory from '@/components/elements/InputHistory.vue'

const woundInitial = {
  wound_number: null,
  practice_type_id: null,
  location: {},
}
const dermTreatmentInitial = {
  lesion_conditions: [],
  lesion_conditions_other: null,
  lesion_description: null,
  topical_agent: null,
  topical_agent_strength: null,
  secondary_topical_agent: null,
  secondary_topical_agent_strength: null,
  current_treatment: null,
  qent_notes: null,
  qent_recommendation: null,
  operative_note: null,
}

export default {
  components: {
    PatientInformation, AttachmentModal, Location, OperativeNote, QuickEntry,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
    encounterId: {
      type: String,
      default: null,
    },
    visitLocation: {
      type: String,
      default: null,
    },
    therapeuticAnticoagulant: {
      type: [Boolean, Number],
      default: null,
    },
    quickEntry: {
      type: Boolean,
      default: null,
    },
    latestTreatment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formValid: false,
      highlightForm: false,
      attachmentModal: false,
      attachmentType: null,
      locationModal: false,
      wound: {},
      treatment: {},
      derm_treatment: {},
      icons: {
        mdiCheck,
        mdiFileImage,
        mdiClose,
        mdiCancel,
        mdiCheckDecagram,
        mdiContentSaveEdit,
        mdiContentSave,
        mdiTrashCan,
      },
    }
  },
  computed: {
    ...mapGetters('baseData', ['dermProcedures']),
    ...mapGetters('encounters', [
      'dermEtiologies',
      'durations',
      'lesionConditions',
      'topicalAgents',
    ]),
    isLoaded() {
      return (this.wound && typeof this.wound.wound_number !== 'undefined')
    },
    isQuickEntry() {
      return (this.$route.query.quick || this.$route.query.quick === 'true') && this.quickEntry
    },
    isNewTreatment() {
      return this.encounterId === this.treatment.encounter_id
    },
    procedureTitle() {
      return this.$store.getters['baseData/procedureFromId'](this.treatment.procedure_id)
    },
    previousProcedureTitle() {
      return this.$store.getters['baseData/procedureFromId'](this.latestTreatment.procedure_id)
    },
    isFootProcedure() {
      return this.treatment.procedure_id !== null
        && this.fieldContains(this.procedureTitle, 'foot')
    },
    invalidFootProcedureLocation() {
      return !this.$custom.isEmpty(this.wound.location)
        && this.isFootProcedure
        && (this.wound.location.location !== 19 && this.wound.location.location !== 4)
    },
    imageAttachmentCount() {
      if (!Array.isArray(this.encounterAttachments)) return false

      return this.encounterAttachments.reduce((count, item) => count + (item.attachment_type === 'images'), 0)
    },
    topicalAgentStrLabel() {
      return this.derm_treatment.topical_agent ? 'Topical Agent Strength' : 'Select topical agent to specify strength'
    },
    secondaryTopicalAgentStrLabel() {
      return this.derm_treatment.secondary_topical_agent ? 'Secondary Topical Agent Strength' : 'Select secondary topical agent to specify strength'
    },
    hasDermTreatment() {
      return this.latestTreatment && this.latestTreatment?.derm_treatment
    },
    previousProceduralNote() {
      return this.latestTreatment && this.latestTreatment.operative_note
        ? this.latestTreatment.operative_note : null
    },
    previousVisitDate() {
      return this.latestTreatment && this.latestTreatment.encounter && this.latestTreatment.encounter.visit_date
        ? this.latestTreatment.encounter.visit_date : null
    },
    previousEtiology() {
      if (this.latestTreatment?.etiology?.includes('Other')) return `Other: ${this.latestTreatment.etiology_other}`

      return this.latestTreatment.etiology
    },
    previousLesionConditions() {
      if (this.hasDermTreatment && this.latestTreatment.derm_treatment.lesion_conditions) {
        const previousLesionConditions = this.latestTreatment.derm_treatment.lesion_conditions.map(lc => lc.title).join(', ')

        if (previousLesionConditions.includes('Other') && this.latestTreatment.derm_treatment.lesion_conditions_other) {
          return previousLesionConditions.replace('Other', this.latestTreatment.derm_treatment.lesion_conditions_other)
        }

        return previousLesionConditions
      }

      return null
    },
  },
  watch: {
    modalState() {
      this.highlightForm = false
      if (!this.modalState) {
        // Reset wound and treatment objects
        this.wound = {}
        this.treatment = {}

        // Scroll to top of modal
        const modalTitle = this.$refs.dermTitle
        if (modalTitle) {
          modalTitle.scrollIntoView(true)
        }
      }
    },
    value() {
      if (this.value && this.value.treatments) {
        const treatmentIndex = this.value.treatments.findIndex(x => x.encounter_id === this.encounterId && !x.deleted)

        this.wound = {
          ...this.$lodash.cloneDeep(woundInitial),
          ...this.$lodash.cloneDeep(this.value),
          treatments: Array.from(this.value.treatments),
        }
        if (!this.wound.location) this.wound.location = {}

        this.wound.treatments[treatmentIndex] = {
          ...this.$lodash.cloneDeep(this.value.treatments[treatmentIndex]),
          derm_treatment: {
            ...this.$lodash.cloneDeep(dermTreatmentInitial),
            ...((this.value.treatments[treatmentIndex]
              && this.value.treatments[treatmentIndex].derm_treatment)
              && this.$lodash.cloneDeep(this.value.treatments[treatmentIndex].derm_treatment)
            ),
          },
        }

        // Telemedicine can't have a treatment, so it must be [999] None.
        if (this.visitLocation === 'Telemedicine') {
          this.wound.treatments[treatmentIndex].procedure_id = 999

          // Also, clear the op note text.
          this.wound.treatments[treatmentIndex].operative_note = null
        }

        // Short references
        this.treatment = this.wound.treatments[treatmentIndex]
        this.derm_treatment = this.wound.treatments[treatmentIndex].derm_treatment
      }
    },
  },
  mounted() {
  },
  methods: {
    clearLocation() {
      this.wound.location = { wound_id: this.wound.id }
    },
    fieldContains(field, contains) {
      return !field ? false : field.toLowerCase().includes(contains)
    },
    attachmentTypeClicked() {
      this.attachmentType = 'images'
      this.attachmentModal = true
    },
    done() {
      this.$emit('update:modal-state', false)
    },
    cancel() {
      this.$root.confirm({
        title: 'Discard Treatment Changes?',
        body: 'Are you sure you wish to discard treatment changes?',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.done()
        }
      })
    },
    deleteTreatment() {
      this.$root.confirm({
        title: 'Delete Treatment Record?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this treatment record?',
        confirm: 'Delete',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.treatment.can_be_deleted) {
            const treatmentIndex = this.wound.treatments.findIndex(x => x.id === this.treatment.id)
            this.wound.treatments.splice(treatmentIndex, 1)
            this.treatment = {}
            if (this.wound.treatments.length === 0 && this.wound.can_be_deleted) this.wound.delete_wound = true
          } else {
            this.wound.updated = true
            this.treatment.updated = true
            this.treatment.deleted = true
          }
          this.$emit('input', this.wound)
          this.done()
        }
      })
    },
    saveDraft() {
      this.treatment.updated = true
      this.wound.updated = true
      this.treatment.is_validated = false
      this.treatment.modified = this.$custom.utcNow()
      this.wound.modified = this.$custom.utcNow()
      this.$emit('input', this.wound)
      this.done()
    },
    validateSave() {
      if (this.formValid) {
        if (!this.isQuickEntry) {
          this.treatment.is_validated = true
        }
        this.treatment.modified = this.$custom.utcNow()
        this.wound.modified = this.$custom.utcNow()
        this.treatment.updated = true
        this.wound.updated = true
        this.$emit('input', this.wound)
        this.done()
      } else {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Derm Treatment Doesn\'t Pass Validation!',
          body: 'Please complete all required and non-validated fields. You will now automatically scroll to the first non-validated field.',
          cancel: false,
        }).then(() => {
          // Highlight non-validated fields and smooth-scroll to first section
          this.highlightForm = true
          const field = this.$refs.dermTitle.closest('.v-dialog').querySelector('.required-field, .v-input--has-state')
          if (field) {
            const section = field.closest('.note-section')
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' })
            }
          }
        })
      }
    },
    handlePreviousOpNote() {
      this.$refs.opNotePrevious.open()
    },
    handlePreviousLesionCondition() {
      this.$refs.lesionCondition.open()
    },
  },
}
</script>
