<template>
  <v-dialog
    v-if="isLoaded"
    v-model="modalState"
    width="900"
    persistent
  >
    <v-card :class="highlightForm && 'highlight-required-fields'">
      <v-form
        v-model="formValid"
        :class="isSigned ? 'form-disabled' : ''"
        :disabled="isSigned"
      >
        <div
          ref="gTubeTitle"
          class="sticky-header"
        >
          <PatientInformation
            :title="`#${wound.wound_number} G-Tube Management`"
            :patient="patient"
          >
            <!-- image attachments -->
            <btn
              label="Images"
              :icon="icons.mdiFileImage"
              class="px-5"
              @click="attachmentTypeClicked"
            >
              <v-badge
                v-if="imageAttachmentCount"
                color="primary"
                :content="imageAttachmentCount"
              >
                Images
              </v-badge>
            </btn>
          </PatientInformation>

          <!-- attachment modal -->
          <attachment-modal
            :attachment-modal.sync="attachmentModal"
            :attachment-type.sync="attachmentType"
            attachment-type-id="3"
            :encounter-attachments.sync="encounterAttachments"
            :patient-id="patient.id"
            :signed="isSigned"
          ></attachment-modal>
        </div>

        <NoteSection label="Procedure Performed">
          <select-box
            v-model="treatment.procedure_id"
            :items="gtubeProcedures"
            label="Procedure Performed"
            class="col-sm-12"
            dense
            required
            :disabled="visitLocation === 'Telemedicine'"
          ></select-box>
        </NoteSection>

        <NoteSection label="Abdominal Exam">
          <select-box
            v-model="g_tube_treatment.abdominal_exam"
            :items="abdominalExams"
            label="Abdominal Exam"
            class="col-sm-12"
            required
          ></select-box>
          <text-area
            v-if="g_tube_treatment.abdominal_exam === 'Other'"
            v-model="g_tube_treatment.abdominal_exam_other"
            label="Enter other abdominal exam..."
            class="col-sm-12"
            required
            counter="1700"
            maxlength="1700"
          ></text-area>
        </NoteSection>

        <NoteSection label="G-Tube Details">
          <select-box
            v-model="g_tube_treatment.g_tube_type"
            :items="gTubeTypes"
            label="G-Tube Type"
            class="col-sm-6"
            :required="isProcedureReplace"
            @click:clear="
              g_tube_treatment.peri_tube_finding = null;
              g_tube_treatment.g_tube_type_other = null;
              g_tube_treatment.duration = null;
              g_tube_treatment.peri_tube_tract = null"
          ></select-box>
          <select-box
            v-model="g_tube_treatment.peri_tube_finding"
            :items="periTubeFindings"
            label="Peri Tube Finding"
            class="col-sm-6"
          ></select-box>
          <text-field
            v-if="g_tube_treatment.g_tube_type === 'Other'"
            v-model="g_tube_treatment.g_tube_type_other"
            label="Enter other G-tube type..."
            class="col-sm-12"
            required
          ></text-field>
          <select-box
            v-model="g_tube_treatment.duration"
            :items="durations"
            label="Duration"
            class="col-sm-6"
          ></select-box>
          <select-box
            v-model="g_tube_treatment.peri_tube_tract"
            :items="periTubeTracts"
            label="Peri Tube Tract"
            class="col-sm-6"
          ></select-box>
        </NoteSection>

        <NoteSection label="Replacement Tube Information">
          <select-box
            v-model="g_tube_treatment.replacement_tube_type"
            :items="gTubeTypes"
            label="Replacement Tube Type"
            class="col-sm-4"
            :required="isProcedureReplace"
            @click:clear="
              g_tube_treatment.balloon_cc = null;
              g_tube_treatment.replacement_size = null;
              g_tube_treatment.replacement_tube_type_other = null"
          ></select-box>
          <select-box
            v-model="g_tube_treatment.balloon_cc"
            :items="balloonCc"
            label="Balloon CC"
            class="col-sm-4"
            :required="isProcedureReplace"
          ></select-box>
          <select-box
            v-model="g_tube_treatment.replacement_size"
            :items="replacementSize"
            label="Replacement Size French"
            class="col-sm-4"
            :required="isProcedureReplace"
          ></select-box>
          <text-field
            v-if="g_tube_treatment.replacement_tube_type === 'Other'"
            v-model="g_tube_treatment.replacement_tube_type_other"
            label="Enter other replacement tube type..."
            class="col-sm-12"
            required
          ></text-field>
          <select-box
            v-model="g_tube_treatment.replacement_reason"
            :items="replacementReasons"
            label="Replacement Reason"
            class="col-sm-12"
            dense
            :required="isProcedureReplace"
          ></select-box>
          <text-area
            v-if="g_tube_treatment.replacement_reason === 'Other'"
            v-model="g_tube_treatment.replacement_reason_other"
            label="Enter other replacement reason..."
            class="col-sm-12"
            required
          ></text-area>
        </NoteSection>

        <NoteSection label="Verification of Placement">
          <select-box
            v-model="g_tube_treatment.verification_of_placement_1"
            :items="verificationOfPlacements"
            label="Verification of Placement"
            class="col-sm-12"
            dense
            :required="isProcedureReplace"
          ></select-box>
          <select-box
            v-if="g_tube_treatment.verification_of_placement_1 || g_tube_treatment.verification_of_placement_2"
            v-model="g_tube_treatment.verification_of_placement_2"
            :items="verificationOfPlacements"
            label="Secondary Verification of Placement (optional)"
            class="col-sm-12"
            dense
          ></select-box>
          <select-box
            v-if="g_tube_treatment.verification_of_placement_2 || g_tube_treatment.verification_of_placement_3"
            v-model="g_tube_treatment.verification_of_placement_3"
            :items="verificationOfPlacements"
            label="Tertiary Verification of Placement (optional)"
            class="col-sm-12"
            dense
          ></select-box>
          <text-area
            v-if="verificationPlacementOther"
            v-model="g_tube_treatment.verification_of_placement_other"
            label="Enter other verification of placement..."
            class="col-sm-12"
            required
          ></text-area>
        </NoteSection>

        <OperativeNote
          v-if="!isQuickEntry"
          v-model="treatment.operative_note"
          :treatments="wound.treatments"
          :treatment-id="treatment.id"
          :highlight-form="highlightForm"
          :procedure-id="treatment.procedure_id"
        ></OperativeNote>

        <NoteSection
          v-if="isQuickEntry"
          label="Quick Entry"
          heading
        ></NoteSection>

        <QuickEntry
          v-if="isQuickEntry"
          :current-treatment.sync="treatment.current_treatment"
          :qent_notes.sync="treatment.qent_notes"
          :operative-note.sync="treatment.operative_note"
          :treatments="wound.treatments"
        ></QuickEntry>

        <v-card-actions class="sticky-bottom">
          <!-- done -->
          <btn
            v-if="isSigned"
            label="Done"
            color="secondary"
            :icon="icons.mdiCheck"
            @click="done"
          ></btn>
          <!-- cancel -->
          <btn
            v-else
            label="Cancel"
            color="secondary"
            :icon="icons.mdiCancel"
            @click="cancel"
          ></btn>
          <!-- delete -->
          <btn
            v-if="isNewTreatment && !isSigned"
            label="Delete"
            color="error"
            :icon="icons.mdiTrashCan"
            rem::disabled="treatment.can_be_deleted !== true"
            @click="deleteTreatment"
          ></btn>

          <v-spacer></v-spacer>

          <!-- save draft -->
          <btn
            v-if="!isSigned"
            label="Save Draft"
            :icon="icons.mdiContentSaveEdit"
            color="success"
            @click="saveDraft"
          ></btn>

          <!-- validate/save -->
          <btn
            v-if="!isSigned"
            label="Validate / Save"
            :icon="icons.mdiCheckDecagram"
            @click="validateSave"
          ></btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiCheck, mdiFileImage, mdiClose, mdiCancel, mdiCheckDecagram, mdiContentSaveEdit,
  mdiContentSave, mdiTrashCan,
} from '@mdi/js'
import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'
import AttachmentModal from '@/components/notes/encounter/AttachmentModal.vue'
import OperativeNote from '@/components/notes/wound-detail/treatments/sections/OperativeNote.vue'
import QuickEntry from '@/components/notes/wound-detail/treatments/sections/QuickEntry.vue'

const woundInitial = {
  wound_number: null,
  practice_type_id: null,
}
const gTubeTreatmentInitial = {
  abdominal_exam: null,
  abdominal_exam_other: null,
  g_tube_type: null,
  g_tube_type_other: null,
  peri_tube_finding: null,
  duration: null,
  peri_tube_tract: null,
  replacement_tube_type: null,
  replacement_tube_type_other: null,
  balloon_cc: null,
  replacement_size: null,
  replacement_reason: null,
  replacement_reason_other: null,
  verification_of_placement_1: null,
  verification_of_placement_2: null,
  verification_of_placement_3: null,
  verification_of_placement_other: null,
  current_treatment: null,
  qent_notes: null,
  qent_recommendation: null,
  operative_note: null,
}

export default {
  components: {
    PatientInformation, AttachmentModal, OperativeNote, QuickEntry,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
    encounterId: {
      type: String,
      default: null,
    },
    visitLocation: {
      type: String,
      default: null,
    },
    quickEntry: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      formValid: false,
      highlightForm: false,
      attachmentModal: false,
      attachmentType: null,
      wound: {},
      treatment: {},
      g_tube_treatment: {},
      icons: {
        mdiCheck,
        mdiFileImage,
        mdiClose,
        mdiCancel,
        mdiCheckDecagram,
        mdiContentSaveEdit,
        mdiContentSave,
        mdiTrashCan,
      },
    }
  },
  computed: {
    ...mapGetters('baseData', [
      'gtubeProcedures',
      'balloonCc',
      'replacementSize',
    ]),
    ...mapGetters('encounters', [
      'abdominalExams',
      'durations',
      'gTubeTypes',
      'periTubeFindings',
      'periTubeTracts',
      'replacementReasons',
      'verificationOfPlacements',
    ]),
    isLoaded() {
      return (this.wound && typeof this.wound.wound_number !== 'undefined')
    },
    isQuickEntry() {
      return (this.$route.query.quick || this.$route.query.quick === 'true') && this.quickEntry
    },
    isNewTreatment() {
      return this.encounterId === this.treatment.encounter_id
    },
    imageAttachmentCount() {
      if (!Array.isArray(this.encounterAttachments)) return false

      return this.encounterAttachments.reduce((count, item) => count + (item.attachment_type === 'images'), 0)
    },
    verificationPlacementOther() {
      return this.g_tube_treatment.verification_of_placement_1 === 'Other'
        || this.g_tube_treatment.verification_of_placement_2 === 'Other'
        || this.g_tube_treatment.verification_of_placement_3 === 'Other'
    },
    isProcedureReplace() {
      const procedure = this.gtubeProcedures.filter(x => x.id === this.treatment.procedure_id)
      if (!procedure || !procedure.length) return false

      return procedure[0].title.toLowerCase().includes('replace')
    },
  },
  watch: {
    modalState() {
      this.highlightForm = false
      if (!this.modalState) {
        // Reset wound and treatment objects
        this.wound = {}
        this.treatment = {}

        // Scroll to top of modal
        const modalTitle = this.$refs.gTubeTitle
        if (modalTitle) {
          modalTitle.scrollIntoView(true)
        }
      }
    },
    value() {
      if (this.value && this.value.treatments) {
        const treatmentIndex = this.value.treatments.findIndex(x => x.encounter_id === this.encounterId && !x.deleted)

        this.wound = {
          ...this.$lodash.cloneDeep(woundInitial),
          ...this.$lodash.cloneDeep(this.value),
          treatments: Array.from(this.value.treatments),
        }

        this.wound.treatments[treatmentIndex] = {
          ...this.$lodash.cloneDeep(this.value.treatments[treatmentIndex]),
          g_tube_treatment: {
            ...this.$lodash.cloneDeep(gTubeTreatmentInitial),
            ...((this.value.treatments[treatmentIndex]
              && this.value.treatments[treatmentIndex].g_tube_treatment)
              && this.$lodash.cloneDeep(this.value.treatments[treatmentIndex].g_tube_treatment)
            ),
          },
        }

        // Telemedicine can't have a treatment, so it must be [999] None.
        if (this.visitLocation === 'Telemedicine') {
          this.wound.treatments[treatmentIndex].procedure_id = 999

          // Also, clear the op note text.
          this.wound.treatments[treatmentIndex].operative_note = null
        }

        // Short references
        this.treatment = this.wound.treatments[treatmentIndex]
        this.g_tube_treatment = this.wound.treatments[treatmentIndex].g_tube_treatment
      }
    },
  },
  mounted() {
  },
  methods: {
    attachmentTypeClicked() {
      this.attachmentType = 'images'
      this.attachmentModal = true
    },
    done() {
      this.$emit('update:modal-state', false)
    },
    cancel() {
      this.$root.confirm({
        title: 'Discard Treatment Changes?',
        body: 'Are you sure you wish to discard treatment changes?',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.done()
        }
      })
    },
    deleteTreatment() {
      this.$root.confirm({
        title: 'Delete Treatment Record?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this treatment record?',
        confirm: 'Delete',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.treatment.can_be_deleted) {
            const treatmentIndex = this.wound.treatments.findIndex(x => x.id === this.treatment.id)
            this.wound.treatments.splice(treatmentIndex, 1)
            this.treatment = {}
            if (this.wound.treatments.length === 0 && this.wound.can_be_deleted) this.wound.delete_wound = true
          } else {
            this.wound.updated = true
            this.treatment.updated = true
            this.treatment.deleted = true
          }
          this.$emit('input', this.wound)
          this.done()
        }
      })
    },
    saveDraft() {
      this.treatment.updated = true
      this.wound.updated = true
      this.treatment.is_validated = false
      this.treatment.modified = this.$custom.utcNow()
      this.wound.modified = this.$custom.utcNow()
      this.$emit('input', this.wound)
      this.done()
    },
    validateSave() {
      if (this.formValid) {
        if (!this.isQuickEntry) {
          this.treatment.is_validated = true
        }
        this.treatment.modified = this.$custom.utcNow()
        this.wound.modified = this.$custom.utcNow()
        this.treatment.updated = true
        this.wound.updated = true
        this.$emit('input', this.wound)
        this.done()
      } else {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'G-Type Treatment Doesn\'t Pass Validation!',
          body: 'Please complete all required and non-validated fields. You will now automatically scroll to the first non-validated field.',
          cancel: false,
        }).then(() => {
          // Highlight non-validated fields and smooth-scroll to first section
          this.highlightForm = true
          const field = this.$refs.gTubeTitle.closest('.v-dialog').querySelector('.required-field, .v-input--has-state')
          if (field) {
            const section = field.closest('.note-section')
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' })
            }
          }
        })
      }
    },
  },
}
</script>
